import React from "react";
import { VStack, Box, StackDivider } from "@chakra-ui/react";
import { useTranslations } from "next-intl";

import Item from "./Item";
import EmptyBox from "components/EmptyBox";
import AdminCard from "components/AdminCard";

interface INewUploadsCardProps {
  data?: any[];
  loading?: boolean;
}

const RenderData = ({ data, loading }: INewUploadsCardProps) => {
  const t = useTranslations("pages.homePage.newUploads");
  if (loading)
    return (
      <VStack w="full" spacing={4} divider={<StackDivider />}>
        {[0, 1, 2].map(i => (
          <Item key={i} isLoading />
        ))}
      </VStack>
    );

  if (data && data.length)
    return (
      <VStack w="full" spacing={4} divider={<StackDivider />}>
        {data.map((athlete: any) => (
          <Item key={athlete.id} isLoading={loading} athlete={athlete} />
        ))}
      </VStack>
    );

  return (
    <Box w="full">
      <VStack w="full" spacing={4} divider={<StackDivider />}>
        <EmptyBox message={t("noUploads")} />
      </VStack>
    </Box>
  );
};

const NewUploadsCard = (props: INewUploadsCardProps) => {
  const t = useTranslations("pages.homePage.newUploads");
  return (
    <AdminCard title={t("trainingUploads")} h="full">
      <RenderData {...props} />
    </AdminCard>
  );
};

export default NewUploadsCard;

import { format, formatDistance, parse, differenceInYears } from "date-fns";
import { pt } from "date-fns/locale";

const timeFromNow = (time: string) => {
  return formatDistance(new Date(time), new Date(), {
    addSuffix: true,
    locale: pt,
  });
};

export const formatDate = (
  time: string | undefined | Date | number,
  dateFormat = "d MMM yyyy"
) => {
  if (time) {
    return format(new Date(time), dateFormat, {
      locale: pt,
    });
  }
  return "";
};

export const calculateAge = (dob: string): number => {
  /* eslint-disable-next-line i18next/no-literal-string */
  const date = parse(dob, "yyyy-MM-dd", new Date());
  const age = differenceInYears(new Date(), date);
  return isNaN(age) ? 0 : age;
};

export default timeFromNow;

import { VStack, Image, Text } from "@chakra-ui/react";
import { LINKS } from "lib/utils/constants";
import { useTranslations } from "next-intl";
import type { ComponentProps } from "react";
import React from "react";

interface IEmptyBoxProps extends ComponentProps<any> {
  message?: string;
  height?: string;
}

const EmptyBox = ({ message, height = "80px", children, ...props }: IEmptyBoxProps) => {
  const t = useTranslations("components.workouts");

  return (
    <VStack w="full" py="10" px="5" {...props}>
      <svg width={height} height={height} viewBox="0 0 280 280" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M41.5004 212.398C49.1164 230.983 61.5344 234.851 70.2652 230.983C87.1503 223.501 131.012 215.143 176.059 234.791C235.722 260.814 248.416 221.552 229.375 181.837C210.334 142.123 203.88 101.96 225.831 79.8331C247.782 57.706 219.057 23.0912 168.044 59.1228C135.078 82.4078 110.884 77.2229 97.8921 70.6403C85.6477 64.4365 72.0305 57.9507 59.2724 63.0148C43.7919 69.1598 37.6921 82.4825 61.245 112.246C94.6214 154.422 24.4281 170.74 41.5004 212.398Z" fill="var(--chakra-colors-brandBlue-50)" />
        <path d="M72.5103 121.864H191.038V213.02C191.038 213.205 190.965 213.383 190.833 213.514C190.702 213.646 190.524 213.72 190.338 213.72H73.2103C73.0246 213.72 72.8465 213.646 72.7153 213.514C72.584 213.383 72.5103 213.205 72.5103 213.02V121.864Z" fill="var(--chakra-colors-brandBlue-200)" />
        <path d="M161.304 86.1428H106.19L72.5103 121.864H191.038L161.304 86.1428Z" fill="var(--chakra-colors-brandBlue-400)" />
        <path d="M83.7369 71.8541L106.19 86.1428L72.5102 121.864L46.9948 102.473L83.7369 71.8541Z" fill="var(--chakra-colors-brandBlue-300)" />
        <path d="M183.757 71.8541L161.303 86.1428L191.038 121.864L220.499 102.473L183.757 71.8541Z" fill="var(--chakra-colors-brandBlue-300)" />
        <path d="M120.67 72.5847C121.304 72.2842 121.797 71.7505 122.046 71.0951C122.295 70.4397 122.282 69.7133 122.008 69.0677L111.907 45.2498C111.764 44.9124 111.554 44.6075 111.29 44.3533C111.026 44.0991 110.714 43.9009 110.371 43.7706C110.029 43.6403 109.663 43.5806 109.297 43.5952C108.931 43.6097 108.571 43.6981 108.24 43.8551L106.294 44.7779C105.963 44.9349 105.667 45.1572 105.424 45.4315C105.181 45.7058 104.996 46.0264 104.88 46.374C104.764 46.7216 104.72 47.0891 104.75 47.4544C104.78 47.8196 104.883 48.175 105.053 48.4993L117.1 71.3944C117.427 72.015 117.981 72.4853 118.646 72.7072C119.311 72.929 120.036 72.8851 120.67 72.5847Z" fill="var(--chakra-colors-brandBlue-400)" />
        <path d="M133.334 70.2757C133.792 70.243 134.22 70.0344 134.529 69.6934C134.837 69.3525 135.002 68.9055 134.989 68.446L134.49 51.4897C134.483 51.2496 134.427 51.0133 134.326 50.7951C134.226 50.577 134.082 50.3814 133.904 50.2203C133.725 50.0591 133.516 49.9356 133.289 49.8573C133.062 49.7789 132.821 49.7473 132.582 49.7644L131.173 49.8648C130.933 49.8818 130.7 49.9471 130.486 50.0568C130.272 50.1664 130.083 50.3182 129.929 50.5029C129.776 50.6875 129.661 50.9014 129.592 51.1315C129.523 51.3616 129.502 51.6033 129.529 51.842L131.436 68.698C131.488 69.155 131.714 69.5743 132.068 69.8683C132.421 70.1623 132.875 70.3083 133.334 70.2757V70.2757Z" fill="var(--chakra-colors-brandBlue-400)" />
        <path d="M143.11 76.5438C144.372 77.2731 146.142 76.6039 147.124 75.0265L164.563 47.0191C165.605 45.3459 165.358 43.3067 164.021 42.5332L162.156 41.4552C160.818 40.6816 158.927 41.4849 157.997 43.2224L142.423 72.3077C141.546 73.9458 141.849 75.8144 143.11 76.5438Z" fill="var(--chakra-colors-brandBlue-400)" />
      </svg>
      <Text color="gray.400">{message || t("noDataToShow")}</Text>
    </VStack>
  );
};

export default EmptyBox;

import React from "react";
import { VStack, Box, StackDivider } from "@chakra-ui/react";
import Item from "./Item";
import EmptyBox from "components/EmptyBox";
import type { IAthlete_With_WeeksFragment } from "lib/api/sdk";
import AdminCard from "components/AdminCard";
import { useTranslations } from "next-intl";

interface IRunningOutOfWorkoutsCardProps {
  data?: IAthlete_With_WeeksFragment[];
  loading?: boolean;
}

const RenderData = ({ data, loading }: IRunningOutOfWorkoutsCardProps) => {
  const t = useTranslations("pages.homePage.runningOutOfWorkouts");
  if (loading)
    return (
      <VStack w="full" spacing={4} divider={<StackDivider />}>
        {[0, 1, 2].map(i => (
          <Item key={i} isLoading />
        ))}
      </VStack>
    );

  if (data && data.length)
    return (
      <VStack w="full" spacing={4} divider={<StackDivider />}>
        {data.map((athlete: any) => (
          <Item key={athlete.id} isLoading={loading} athlete={athlete} />
        ))}
      </VStack>
    );

  return (
    <Box w="full">
      <VStack w="full" spacing={4} divider={<StackDivider />}>
        <EmptyBox message={t("noAthletesToShow")} />
      </VStack>
    </Box>
  );
};

const RunningOutOfWorkoutsCard = (props: IRunningOutOfWorkoutsCardProps) => {
  const t = useTranslations("pages.homePage.runningOutOfWorkouts");
  return (
    <AdminCard h="full" title={t("athletesWithoutWorkouts")}>
      <RenderData {...props} />
    </AdminCard>
  );
};

export default RunningOutOfWorkoutsCard;

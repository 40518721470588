import type { ComponentProps } from "react";
import React, { useState } from "react";
import {
  Box,
  Heading,
  HStack,
  Icon,
  Select,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  isBefore,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subWeeks,
  isAfter,
  setYear,
  isSameYear,
} from "date-fns";
import flattenDeep from "lodash/flattenDeep";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import BirthdaysCard from "./Birthdays/BirthdaysCard";
import RunningOutOfWorkoutsCard from "./RunningOutOfWorkouts/RunningOutOfWorkoutsCard";
import NewUploadsCard from "./NewUploads/NewUploadsCard";
import AdminSidebarLayout from "components/layout/AdminSidebarLayout/AdminSidebarLayout";
import {
  AthletesIcon,
  BlocksIcon,
  CustomersIcon,
  PaymentsIcon,
} from "components/Icons/Icons";
import type { IAdminDashboardQuery } from "lib/api/sdk";
import { useAdminDashboardQuery } from "lib/api/sdk";
import { formatDate } from "lib/utils/dates/dateFormater";
import { getPaths } from "lib/utils/paths";
import { useUser } from "src/contexts/useUser";

const ShortcutBox = ({ icon, label, color, ...props }: ComponentProps<any>) => (
  <Box
    as="button"
    bg={color}
    px={4}
    py={5}
    flex={1}
    maxW={"300px"}
    _hover={{ shadow: "lg" }}
    transition="all ease 0.3s"
    {...props}
  >
    <HStack spacing={8}>
      <Icon color={"white"} fontSize="3xl" as={icon} />
      <Heading size="md" textTransform={"uppercase"} color={"white"}>
        {label}
      </Heading>
    </HStack>
  </Box>
);

const GetTimeFrameAthleteBirthdays = (
  timeFrame: Date[],
  data?: IAdminDashboardQuery
) =>
  data?.athletes_with_birthdays.filter(athlete => {
    const normalizedBday = setYear(new Date(athlete.birthday), 2000);
    const normalizedTimeFrame = [
      setYear(
        new Date(timeFrame[0]),
        isSameYear(timeFrame[0], timeFrame[1]) ? 2000 : 1999
      ),
      setYear(new Date(timeFrame[1]), 2000),
    ];

    return (
      isAfter(normalizedBday, normalizedTimeFrame[0]) &&
      isBefore(normalizedBday, normalizedTimeFrame[1])
    );
  });

const GetAthletesWithoutWorkouts = (
  timeFrame: Date[],
  data?: IAdminDashboardQuery
) => {
  const dateToCompare = isAfter(new Date(timeFrame[1]), new Date())
    ? new Date(timeFrame[1])
    : new Date();

  return data?.athletes_with_weeks.filter(athlete => {
    return (
      !athlete.athlete_blocks?.length ||
      !athlete.athlete_blocks[0].athlete_weeks.length ||
      isBefore(
        new Date(athlete.athlete_blocks[0].athlete_weeks[0].end_date),
        dateToCompare
      )
    );
  });
};

const GetAthletesWithUploads = (
  timeFrame: Date[],
  data?: IAdminDashboardQuery
) => {
  return data?.athletes_with_uploads
    .map(athlete => {
      const workouts = flattenDeep(
        athlete.athlete_blocks.map(block =>
          block.athlete_weeks.map(weeks =>
            weeks.athlete_workouts.filter(workout => {
              const hasResults =
                workout.comments ||
                workout.motivation ||
                workout.effort ||
                workout.athlete_workout_sections.filter(
                  section =>
                    section.measured_average_heart_rate ||
                    section.measured_duration ||
                    section.measured_max_heart_rate ||
                    section.measured_speed
                ).length;

              return (
                hasResults &&
                isAfter(new Date(workout.date), new Date(timeFrame[0])) &&
                isBefore(new Date(workout.date), new Date(timeFrame[1]))
              );
            })
          )
        )
      );

      if (workouts.length) return { ...athlete, workouts };
      else return { ...athlete };
    })
    .filter(r => !!r);
};

export default function AdminHomePage() {
  const { user_name, user_type, isAdmin } = useUser();
  const router = useRouter();
  const t = useTranslations("pages.homePage");
  // eslint-disable-next-line i18next/no-literal-string
  const [timeFrame, setTimeFrame] = useState("this_week");

  const TIME_FRAMES: any = {
    this_week: {
      label: t("thisWeek"),
      time_frame: [startOfWeek(new Date()), endOfWeek(new Date())],
    },
    this_month: {
      label: t("thisMonth"),
      time_frame: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    last_7_days: {
      label: t("last7Days"),
      time_frame: [subWeeks(startOfDay(new Date()), 1), endOfDay(new Date())],
    },
    last_14_days: {
      label: t("last14Days"),
      time_frame: [subWeeks(startOfDay(new Date()), 2), endOfDay(new Date())],
    },
    last_30_days: {
      label: t("last30Days"),
      time_frame: [subDays(startOfDay(new Date()), 30), endOfDay(new Date())],
    },
  };

  const DashboardReq = useAdminDashboardQuery({
    variables: {
      _gte: TIME_FRAMES[timeFrame].time_frame[0],
      _gte_date: TIME_FRAMES[timeFrame].time_frame[0],
      _lte: TIME_FRAMES[timeFrame].time_frame[1],
      _lte_date: TIME_FRAMES[timeFrame].time_frame[1],
    },
  });

  return (
    <AdminSidebarLayout>
      <VStack flexGrow={1} w="full" p={8} spacing={8} align="stretch">
        <Heading>
          {t("welcome", { name: user_name || user_type })}
        </Heading>
        {isAdmin && (
          <VStack align="stretch" spacing={8}>
            <Wrap spacing={5}>
              <ShortcutBox
                icon={AthletesIcon}
                label={t("athletes")}
                onClick={() => router.push(getPaths.atletas.index())}
                color="#E36D00"
              />
              <ShortcutBox
                icon={BlocksIcon}
                label={t("trainingBlocks")}
                onClick={() => router.push(getPaths.treinos.blocos.index())}
                color="#5386E4"
              />
              <ShortcutBox
                icon={CustomersIcon}
                label={t("coaches")}
                onClick={() => router.push(getPaths.treinadores.index())}
                color="#4C4B63"
              />
              <ShortcutBox
                icon={PaymentsIcon}
                label={t("subscriptions")}
                onClick={() => router.push(getPaths.planosSubscricoes.subscricoes.index())}
                color="#03B5AA"
              />
            </Wrap>
          </VStack>
        )}
        <VStack align="stretch" spacing={8}>
          <HStack justify={"space-between"}>
            <VStack flex={2} align={"flex-start"} spacing={1}>
              <Heading size={"md"}>{TIME_FRAMES[timeFrame].label}</Heading>
              <Text
                fontWeight={"bold"}
                fontSize={"sm"}
                textTransform={"capitalize"}
              >
                {formatDate(TIME_FRAMES[timeFrame].time_frame[0])} -{" "}
                {formatDate(TIME_FRAMES[timeFrame].time_frame[1])}
              </Text>
            </VStack>

            <Select
              flex={1}
              onChange={e => setTimeFrame(e.target.value)}
              bg="white"
            >
              {Object.keys(TIME_FRAMES).map(tf => (
                <option key={tf} value={tf}>
                  {TIME_FRAMES[tf].label}
                </option>
              ))}
            </Select>
          </HStack>
          <HStack spacing={8} align={"stretch"}>
            <Box flex={1}>
              <RunningOutOfWorkoutsCard
                data={GetAthletesWithoutWorkouts(
                  TIME_FRAMES[timeFrame].time_frame,
                  DashboardReq.data
                )}
                loading={DashboardReq.loading}
              />
            </Box>
          </HStack>
          <HStack spacing={8} align={"stretch"}>
            <Box flex={1}>
              <NewUploadsCard
                data={GetAthletesWithUploads(
                  TIME_FRAMES[timeFrame].time_frame,
                  DashboardReq.data
                )}
                loading={DashboardReq.loading}
              />
            </Box>
            <Box flex={1}>
              <BirthdaysCard
                data={GetTimeFrameAthleteBirthdays(
                  TIME_FRAMES[timeFrame].time_frame,
                  DashboardReq.data
                )}
                loading={DashboardReq.loading}
              />
            </Box>
          </HStack>
        </VStack>
      </VStack>
    </AdminSidebarLayout>
  );
}

import React from "react";
import { VStack, Box, StackDivider } from "@chakra-ui/react";
import { useTranslations } from "next-intl";

import Item from "./Item";
import EmptyBox from "components/EmptyBox";
import type { IAthlete_With_BirthdayFragment } from "lib/api/sdk";
import AdminCard from "components/AdminCard";

interface IBirthdaysCardProps {
  data?: IAthlete_With_BirthdayFragment[];
  loading?: boolean;
}

const RenderData = ({ data, loading }: IBirthdaysCardProps) => {
  const t = useTranslations("pages.homePage.birthdays");

  if (loading)
    return (
      <VStack spacing={4} w="full" divider={<StackDivider />}>
        {[0, 1, 2].map(i => (
          <Item key={i} isLoading />
        ))}
      </VStack>
    );

  if (data && data.length)
    return (
      <VStack spacing={4} w="full" divider={<StackDivider />}>
        {data.map((athlete: IAthlete_With_BirthdayFragment) => (
          <Item key={athlete.id} isLoading={loading} athlete={athlete} />
        ))}
      </VStack>
    );

  return (
    <Box w="full">
      <VStack spacing={4} w="full">
        <EmptyBox message={t("noBirthdays")} />
      </VStack>
    </Box>
  );
};

const BirthdaysCard = (props: IBirthdaysCardProps) => {
  const t = useTranslations("pages.homePage.birthdays");
  return (
    <AdminCard title={t("birthdays")} h="full">
      <RenderData {...props} />
    </AdminCard>
  );
};

export default BirthdaysCard;

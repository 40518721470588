import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import type {
  BoxProps
} from "@chakra-ui/react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import React from "react";

interface IAdminCardProps extends BoxProps {
  title?: any;
  rightSideComponent?: any;
  isOpen?: boolean;
  titleSize?: "sm" | "md" | "lg";
  dragHandler?: React.ReactNode;
}

const AdminCard = ({
  title,
  rightSideComponent,
  isOpen,
  onClick,
  titleSize,
  children,
  dragHandler,
  ...props
}: IAdminCardProps) => {
  const t = useTranslations("components.workouts");

  return (
    <VStack
      align="flex-start"
      w="full"
      px={5}
      py={5}
      bg="white"
      boxShadow="sm"
      {...props}
    >
      {(title || rightSideComponent) && (
        <HStack
          onClick={onClick}
          justify="space-between"
          align="center"
          mb={isOpen === false ? 0 : "5"}
          w="full"
          cursor={isOpen !== undefined ? "pointer" : "auto"}
        >
          {dragHandler ? dragHandler : null}
          <Box flex={1}>
            <Heading fontSize={titleSize || "xl"}>{title}</Heading>
          </Box>

          <Flex dir="row" justify="flex-end">
            {rightSideComponent}
            {isOpen !== undefined && isOpen && (
              <HStack>
                <Text color="gray.800" fontSize={"sm"} letterSpacing="wider">
                  {t("seeLess")}
                </Text>
                <ChevronUpIcon color="gray.800" fontSize="xl" />
              </HStack>
            )}
            {isOpen !== undefined && !isOpen && (
              <HStack>
                <Text color="gray.800" fontSize={"sm"} letterSpacing="wider">
                  {t("seeMore")}
                </Text>{" "}
                <ChevronDownIcon color="gray.800" fontSize="xl" />
              </HStack>
            )}
          </Flex>
        </HStack>
      )}
      {isOpen !== false && (
        <Box flexGrow={1} w="full">
          {children}
        </Box>
      )}
    </VStack>
  );
};

export default AdminCard;

import { HStack, Icon } from "@chakra-ui/react";
import type { ComponentProps } from "react";
import React from "react";
import {
  FaBiking,
  FaHiking,
  FaMountain,
  FaQuestion,
  FaRunning,
  FaSkiing,
  FaSkiingNordic,
  FaSwimmer,
} from "react-icons/fa";
import { GiFootprint } from "react-icons/gi";

interface ISportIconsFactory extends ComponentProps<any> {
  id?: string;
  spacing?: number;
}

const SportIconsFactory = ({ id, spacing = 2, ...props }: ISportIconsFactory) => {
  const wrapperProps = {
    spacing,
    // eslint-disable-next-line i18next/no-literal-string
    align: "center",
    // eslint-disable-next-line i18next/no-literal-string
    justify: "center",
  };

  switch (id) {
    case "icon-running":
      return <Icon as={FaRunning} {...props} />;
    case "icon-cycling":
      return <Icon as={FaBiking} {...props} />;
    case "icon-trail":
      return (
        <HStack {...wrapperProps}>
          <Icon as={FaRunning} {...props} />
          <Icon as={FaMountain} {...props} />
        </HStack>
      );
    case "icon-trek":
      return <Icon as={FaHiking} {...props} />;
    case "icon-ski":
      return <Icon as={FaSkiingNordic} {...props} />;
    case "icon-swimming":
      return <Icon as={FaSwimmer} {...props} />;
    case "icon-vertical-ski":
      return <Icon as={FaSkiing} {...props} />;
    case "icon-bfr":
      return (
        <HStack {...wrapperProps}>
          <Icon as={FaRunning} {...props} />
          <Icon as={GiFootprint} {...props} />
        </HStack>
      );
    case "icon-triatlon":
      return (
        <HStack {...wrapperProps}>
          <Icon as={FaRunning} {...props} />
          <Icon as={FaBiking} {...props} />
          <Icon as={FaSwimmer} {...props} />
        </HStack>
      );

    default:
      return <Icon as={FaQuestion} {...props} />;
  }
};

export default SportIconsFactory;

import PrivatePage from "components/auth/PrivatePage";
import HomePage from "pages/HomePage/HomePage";
import { getStaticProps as gsp } from "locales/utils";

export default function AdminHomePage() {
  return (
    <PrivatePage>
      <HomePage />
    </PrivatePage>
  );
}

export const getStaticProps = gsp;

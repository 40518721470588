import type { ComponentProps } from "react";
import router from "next/router";

import { getPaths } from "lib/utils/paths";
import AdminLoadingPage from "pages/LoadingPage";
import { useUser } from "src/contexts/useUser";
import { SignInRoles } from "lib/utils/constants";

export default function PrivatePage({
  children,
  adminOnly = false,
  contentAccess = false
}: ComponentProps<any>) {
  const { loading, loggedIn, signOut, user_type, coach } = useUser();

  if (adminOnly && user_type !== SignInRoles.ADMIN) {
    router.push(getPaths[404]());
    return null;
  }

  const hasAccessToContent = user_type === SignInRoles.ADMIN
    || user_type === SignInRoles.COACH && coach?.platform_content_access;
  if (contentAccess && !hasAccessToContent) {
    router.push(getPaths[404]());
    return null;
  }

  if (!loggedIn) {
    signOut();
    return null;
  }

  if (loading)
    return <AdminLoadingPage />;

  return children;
}
